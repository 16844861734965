import LayoutBody from "@/layout/layout-body";

export default [
  {
    path: "/voluntariado",
    component: LayoutBody,
    children: [
      {
        path: "",
        component: () => import("@/views/voluntariado/voluntariado"),
      },
      {
        path: "/voluntariado/actividades",
        component: () => import("@/views/voluntariado/voluntariado-actividades"),
      },
      {
        path: "/voluntariado/coordinadores",
        component: () => import("@/views/voluntariado/voluntariado-coordinadores"),
      },
    ],
  }
];
