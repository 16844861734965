<template>
  <div id="app">
    <router-view />
  </div>
</template>


<script>
export default {
  name: "App",
  metaInfo: {
    title: "Home",
    titleTemplate: "%s",
  }
};
</script>