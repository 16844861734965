<template>
  <footer class="bg-dark mt-5" >
    <div class="container pt-7 p-4 ">
      <div class="row">
        <div class="col-sm-6 col-md-6 col-lg-5 mb-4 mb-lg-0">
          <img alt="Transparencia" :src="`${publicUrl}img/misc/logo-transparencia.png`" class="mb-4" width="180" />
          <p>
            <span class="m-link-light">Asociación Civil Transparencia</span>
          </p>
          <div class="social" style="color:white;">
            <a target="_blank" class="social-facebook m-0" href="https://www.facebook.com/transparenciaperu?_rdc=1&_rdr"><i class="fab fa-facebook"></i></a>
            <a target="_blank" class="social-twitter m-0" href="https://twitter.com/actransparencia"><i class="fab fa-twitter"></i></a>
            <a target="_blank" class="social-youtube m-0" href="https://www.youtube.com/user/ACTRANSPARENCIAPERU"><i class="fab fa-youtube"></i></a>
            <a target="_blank" class="social-instagram m-0" href="https://www.instagram.com/actransparencia/"><i class="fab fa-instagram"></i></a>
            <a  target="_blank"
              class="social-tiktok m-0"
              href="https://www.tiktok.com/@actransparencia?_d=secCgYIASAHKAESMgowVRNo2r3E3%2B2rxfutKzjdjHv5uSn3qk2G50Ynwh4YHpwle1X%2FhMs3Jnzg8uMw8IL3GgA%3D&language=es&sec_uid=MS4wLjABAAAAzKgujiOkMnrMLGJW8_5Ej_be5pF5XI8C92uEhbzbJTJFVHmdRZV4Gkrd6g7O9HEd&sec_user_id=MS4wLjABAAAAzKgujiOkMnrMLGJW8_5Ej_be5pF5XI8C92uEhbzbJTJFVHmdRZV4Gkrd6g7O9HEd&share_author_id=6808696092986983430&share_link_id=046BB497-2E25-4C12-A8B4-6AB66CA29F3D&tt_from=copy&u_code=dbfl0573j4lk9a&user_id=6808696092986983430&utm_campaign=client_share&utm_medium=ios&utm_source=copy&source=h5_m&_r=1"
            >
              <svg style="height:15px" fill="#fff" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="tiktok" class="text-white" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"></path></svg>
            </a>
          </div>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-4 mb-4 mb-lg-0">
          <h3 class="h6 mb-3 text-white">
            Acceso rápido
          </h3>
          <ul class="list-unstyled mb-0">
            <li>
              <router-link class="m-list--link" :class="isMenuActive('/home') ? 'text-warning' : ''" to="/home">Inicio</router-link>
            </li>
            <li>
              <router-link class="m-list--link" :class="isMenuActive('/nosotros') ? 'text-warning' : ''" to="/nosotros">Nosotros</router-link>
            </li>
            <li>
              <router-link class="m-list--link" :class="isMenuActive('/nuestrotrabajo') ? 'text-warning' : ''" to="/nuestrotrabajo">Nuestro trabajo</router-link>
            </li>
            <li>
              <router-link class="m-list--link" :class="isMenuActive('/voluntariado') ? 'text-warning' : ''" to="/voluntariado">Voluntariado</router-link>
            </li>
            <li>
              <router-link class="m-list--link" :class="isMenuActive('/blog') ? 'text-warning' : ''" to="/blog">Blog</router-link>
            </li>
            <li>
              <router-link class="m-list--link" :class="isMenuActive('/publicaciones') ? 'text-warning' : ''" to="/publicaciones">Publicaciones</router-link>
            </li>
          </ul>
        </div>
        <!-- <div class="col-sm-3 col-md-3 col-lg-2 mb-4 mb-lg-0">
          <h3 class="h6 mb-3 text-white">
            Redes sociales
          </h3>
          <div class="social" style="color:white;">
            <a class="social-facebook m-0" href="https://www.facebook.com/transparenciaperu?_rdc=1&_rdr"><i class="fab fa-facebook"></i></a>
            <a class="social-twitter m-0" href="https://twitter.com/actransparencia"><i class="fab fa-twitter"></i></a>
            <a class="social-youtube m-0" href="https://www.youtube.com/user/ACTRANSPARENCIAPERU"><i class="fab fa-youtube"></i></a>
            <a class="social-instagram m-0" href="https://www.instagram.com/actransparencia/"><i class="fab fa-instagram"></i></a>
            <a
              class="social-tiktok m-0"
              href="https://www.tiktok.com/@actransparencia?_d=secCgYIASAHKAESMgowVRNo2r3E3%2B2rxfutKzjdjHv5uSn3qk2G50Ynwh4YHpwle1X%2FhMs3Jnzg8uMw8IL3GgA%3D&language=es&sec_uid=MS4wLjABAAAAzKgujiOkMnrMLGJW8_5Ej_be5pF5XI8C92uEhbzbJTJFVHmdRZV4Gkrd6g7O9HEd&sec_user_id=MS4wLjABAAAAzKgujiOkMnrMLGJW8_5Ej_be5pF5XI8C92uEhbzbJTJFVHmdRZV4Gkrd6g7O9HEd&share_author_id=6808696092986983430&share_link_id=046BB497-2E25-4C12-A8B4-6AB66CA29F3D&tt_from=copy&u_code=dbfl0573j4lk9a&user_id=6808696092986983430&utm_campaign=client_share&utm_medium=ios&utm_source=copy&source=h5_m&_r=1"
            >
              <svg style="height:15px" fill="#fff" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="tiktok" class="text-white" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"></path></svg>
            </a>
          </div>
        </div> -->
        <div class="col-sm-3 col-md-3 col-lg-3 mb-4 mb-lg-0">
          <h3 class="h6 mb-3 text-white">
            Contacto
          </h3>

          <address class="fs-14 text-white-50">
            <strong class="text-white">Transparencia</strong><br />
            Av. Arnaldo Márquez N° 2277<br />
            Jesús María<br />
            (511) 441-3234
          </address>
          <address class="fs-14">
            <strong class="text-white">Correo</strong><br />
            <a class="m-link" href="mailto:#">contactenos@transparencia.org.pe</a>
          </address>
        </div>
      </div>
    </div>

    <!-- Copyright -->
    <div class="container pt-2 pb-2 border-top-muted">
      <div class="row">
        <div class="col-md-12 text-center">
          <span class="fs-14 text-white "><span class="fw-500">©</span> 2021 <a class="text-white" target="_blank" href="https://stratlabs.pe/">StratLabs</a>. All rights reserved.</span>
        </div>
      </div>
    </div>
    <div class="container-fluid border-top-muted">
      <div class="row text-center text-dark" style="background-color:#F3F3F3;">
        <div class="col-md-12 pt-2 pb-2">
          <small>Todo este contenido ha sido publicado bajo licencia de Asociación Civil Transparencia</small>
        </div>
      </div>
    </div>
    <!-- End Copyright -->
  </footer>
</template>

<script>
export default {
  name: "app-layout-footer",

  props: {},

  methods: {
    isMenuActive(url) {
      return this.$route.path.indexOf(url) === 0;
    },
  },
};
</script>
