import 'custom-event-polyfill';
import 'url-polyfill';

import Vue from 'vue';
import App from './App';
import router from './router';

import BootstrapVue from 'bootstrap-vue';
import VueResource from 'vue-resource';

import globals from './globals';

import LoadScript from 'vue-plugin-load-script';

import Popper from 'popper.js';
import moment from "moment";
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
    config: { id: "G-3ZCNY8E7F7"}
    //config: { id: "G-AAAAA"}
},router);

Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;
Vue.config.productionTip = false;

Vue.use(LoadScript);
Vue.use(BootstrapVue);
Vue.use(VueResource);

let prod = (process.env.NODE_ENV === 'production' ? '/' : '/');
Vue.loadScript( prod + "assets/js/custom.js");

Vue.filter('date', function (value, format) {
    if (value) {
        return moment(String(value)).format(format || 'DD/MM/YYYY')
    }
});

Vue.mixin({
    data: globals,
  });

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
