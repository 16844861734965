import LayoutBody from '@/layout/layout-body'

export default [{
    path: '/publicaciones',
    component: LayoutBody,
    children: [{
        path: '',
        component: () => import('@/views/publicaciones/publicaciones')
    }]
}]
