<template>
  <div>
    <layout-header></layout-header>
    <main id="content" class="section-container-color">
      <router-view />
    </main>
    <layout-footer></layout-footer>
    <layout-social-media></layout-social-media>
  </div>
</template>

<script>
import layoutHeader from "./layout-header";
import layoutFooter from "./layout-footer";
import layoutSocialMedia from "./layout-social-media";

export default {
  name: "app-layout-body",
  components: {
    layoutHeader,
    layoutFooter,
    layoutSocialMedia,
    
  },
  props: {},
  methods: {},
};
</script>
