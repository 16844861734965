import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";
import NotFound from "@/components/NotFound";
import VueRouterBackButton from 'vue-router-back-button'
// Layouts
import home from "./homes";
import nuestrotrabajo from "./nuestrotrabajos";
import nosotros from "./nosotros";
import voluntariados from "./voluntariados";
import blogs from "./blogs";
import publicacions from "./publicacions";
// import LayoutBlank from '@/layout/LayoutBlank'

Vue.use(Router);
Vue.use(Meta);

const ROUTES = [{ path: "", redirect: "/home" }]
  .concat(home)
  .concat(nosotros)
  .concat(voluntariados)
  .concat(blogs)
  .concat(publicacions)
  .concat(nuestrotrabajo);
  
// 404 Not Found page
ROUTES.push({
  path: "*",
  component: NotFound,
});

const router = new Router({
  base: process.env.BASE_URL,
  mode: "history",
  routes: ROUTES,
});

Vue.use(VueRouterBackButton, { router });

export default router;
