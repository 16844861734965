import LayoutBody from '@/layout/layout-body';

export default [{
    path: '/home',
    component: LayoutBody,
    children: [
        {
            path: '',
            component: () => import('@/views/home/home')
        },
        {
            path: 'noticias',
            component: () => import('@/views/home/noticias')
        },
        {
            path: '/home/noticia/:id',
            component: () => import('@/views/home/noticia-detalle')
        }
        ,
        {
            path: '/voluntariado/detalle/:id',
            component: () => import('@/views/voluntariado/voluntariado-detalle')
        }
    ]
}]
