<template>
  <header id="header">
    <div class="header-body" style="position:sticky">
      <div class="header-container container">
        <div class="header-row">
          <div class="header-column">
            <div class="header-row">
              <div class="header-logo">
                <router-link to="/home">
                  <img alt="Transparencia" width="96" height="48" data-sticky-width="100" data-sticky-height="25" data-sticky-top="26" :src="`${publicUrl}img/misc/logo-transparencia.png`" />
                </router-link>
              </div>
            </div>
          </div>
          <div class="header-column justify-content-end">
            <div class="header-row">
              <div class="header-nav header-nav-links order-2 order-lg-1">
                <div class="header-nav-main header-nav-main-square header-nav-main-effect-2 header-nav-main-sub-effect-1">
                  <nav class="collapse">
                    <ul class="nav nav-pills" id="mainNav">
                      <li class="dropdown dropdown-mega">
                        <router-link class="dropdown-item dropdown-toggle text-center" :class="isMenuActive('/home') ? 'active' : ''" to="/home">Inicio</router-link>
                      </li>
                      <li class="dropdown">
                        <router-link class="dropdown-item dropdown-toggle text-center" :class="isMenuActive('/nosotros') ? 'active' : ''" to="/nosotros">Nosotros</router-link>
                      </li>
                      <li class="dropdown">
                        <router-link class="dropdown-item dropdown-toggle text-center" :class="isMenuActive('/nuestrotrabajo') ? 'active' : ''" to="/nuestrotrabajo">Nuestro trabajo</router-link>
                      </li>
                      <li class="dropdown">
                        <router-link class="dropdown-item dropdown-toggle  text-center" :class="isMenuActive('/voluntariado') ? 'active' : ''" to="/voluntariado">Voluntariado</router-link>
                      </li>
                      <li class="dropdown dropdown-mega">
                        <router-link class="dropdown-item dropdown-toggle text-center" :class="isMenuActive('/blog') ? 'active' : ''" to="/blog">Blog</router-link>
                      </li>
                      <li class="dropdown d-sm-none d-md-block">
                        <router-link class="dropdown-item dropdown-toggle  text-center" :class="isMenuActive('/publicaciones') ? 'active' : ''" to="/publicaciones">Publicaciones</router-link>
                      </li>
                      <!-- <li class="dropdown n-menu-item-full">
                        <a class="dropdown-item dropdown-toggle" href="https://observatoriointegridad.pe/" target="_blank">
                          <div class="w-100 p-3 fw-600" style="line-height:1rem !important;background-color:#fff !important;border-radius:12px;">
                            <span class="text-transparencia">Observatorio <br />de </span> <span style="color:#9D0B2C">&nbsp;integridad</span>
                          </div>
                        </a>
                      </li>
                      <li class="dropdown n-menu-item-mobile">
                        <a class="dropdown-item dropdown-toggle" href="https://observatoriointegridad.pe/" target="_blank">
                          <div class="w-100 p-3 fw-600 text-center" style="line-height:1rem !important;background-color:#fff !important;border-radius:12px;"><span class="text-transparencia">Observatorio de </span><span style="color:#9D0B2C">Integridad</span></div>
                        </a>
                      </li> -->
                      <li class="dropdown n-menu-item-full">
                        <a class="dropdown-item dropdown-toggle" href="https://infocongresistasperu.pe/" target="_blank">
                          <div class="w-100 p-3 pt-4 pb-4 fw-600" style="line-height:1rem !important;background-color:#fff !important;border-radius:12px;"><span style="color:#DCA514 !important">info</span><span class="text-transparencia ">Congresistas</span></div>
                        </a>
                      </li>
                      <li class="dropdown n-menu-item-mobile">
                        <a class="dropdown-item dropdown-toggle n-menu-item-mobile" href="https://infocongresistasperu.pe/" target="_blank">
                          <div class="w-100 p-3 fw-600 text-center" style="line-height:1rem !important;background-color:#fff !important;border-radius:12px;"><span style="color:#DCA514 !important">info</span><span class="text-transparencia ">Congresistas</span></div>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
                <button class="btn header-btn-collapse-nav" data-toggle="collapse" data-target=".header-nav-main nav">
                  <i class="fas fa-bars"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "app-layout-header",

  props: {},
  components: {},
  methods: {
    isMenuActive(url) {
      return this.$route.path.indexOf(url) === 0;
    },
  },
};
</script>
