import Vue from 'vue';

export default function () {
  return {

    publicUrl: process.env.BASE_URL,

    // apiUrl: "http://localhost:44027/api/",
    // filesUrl: "http://localhost:44027/",

    apiUrl: '//api.transparencia.org.pe/api/',
    filesUrl: '//api.transparencia.org.pe/',

    // apiUrl: process.env.NODE_ENV === 'production' ? '//api.transparencia.org.pe/api/' : 'http://localhost:44027/api/',
    // filesUrl: process.env.NODE_ENV === 'production' ? '//api.transparencia.org.pe/' : 'http://localhost:44027/',

    breadcrumbs: [],
    breadcrumbsTest: Vue.observable({ list: [] }),
    breadcrumbsRender() {
      return this.breadcrumbs;
    },
    breadcrumbsSetup(lista) {
      this.breadcrumbs = lista;
    },
    fileUrl(path) {
      if (path) return this.filesUrl + path;
      else return this.publicUrl + "img/misc/logo-transparencia-default.png";
    },

    fileWebUrl(path) {
      if (path) return this.publicUrl + path;
      else return this.publicUrl + "img/misc/logo-transparencia-default.png";
    },

    apiRoute(controller, action) {
      return this.apiUrl + controller + "/" + action;
    },

    apiResource(path) {
      return this.filesUrl + path;
    },
    formatPrice(value) {
      if (value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      }
      else {
        return "";
      }
    },
    /* VARIABLES APP */
    tipoContenidoArticulo: [
      { codigo: "NOC", titulo: "Sin contenido multimedia" },
      { codigo: "SIM", titulo: "Simple" },
      { codigo: "IMG", titulo: "Imagenes múltiples" },
      { codigo: "VID", titulo: "Videos múltiples" },
      { codigo: "IVM", titulo: "Imágenes y videos múltiples" },
    ],
    getTipoContenidoArticulo(codigo) {
      var resultado = this.tipoContenidoArticulo.filter((x) => x.codigo == codigo);
      if (resultado != null) {
        return resultado[0].titulo;
      }
      return "";
    },
    tipoPersona: [
      { codigo: "CDR", titulo: "Consejo Directivo 2020 - 2023" },
      { codigo: "ADA", titulo: "Asamblea de Asociados" },
      { codigo: "EQU", titulo: "Equipo" },
      { codigo: "VOC", titulo: "Vocales" },
    ],
    getTipoPersona(codigo) {
      var resultado = this.tipoPersona.filter((x) => x.codigo == codigo);
      if (resultado != null) {
        return resultado[0].titulo;
      }
      return "";
    },
    tipoPersonaEstado: [
      { codigo: "HAB", titulo: "Habilitado" },
      { codigo: "CON", titulo: "Con licencia" },
    ],
    getTipoPersonaEstado(codigo) {
      var resultado = this.tipoPersonaEstado.filter((x) => x.codigo == codigo);
      if (resultado != null) {
        return resultado[0].titulo;
      }
      return "";
    },
    ambitoTransparencia: [
      { codigo: "NAC", titulo: "Nacional" },
      { codigo: "INT", titulo: "Internacional" },
    ],
    getAmbitoTransparencia(codigo) {
      var resultado = this.ambitoTransparencia.filter((x) => x.codigo == codigo);
      if (resultado != null) {
        return resultado[0].titulo;
      }
      return "";
    },
    arrTipoMonedas: [
      { codigo: "PEN", titulo: "Soles", simbolo: "S/" },
      { codigo: "USD", titulo: "Dólar", simbolo: "$" },
    ],
    getTipoMoneda(codigo) {
      var resultado = this.arrTipoMonedas.filter((x) => x.codigo == codigo);
      if (resultado != null && resultado.length > 0) {
        return resultado[0];
      }
      return "";
    },
    arrEstadoProyecto: [
      { codigo: "EJE", titulo: "EJECUCIÓN" },
      { codigo: "FIN", titulo: "FINALIZADO" },
    ],
    getEstadoProyecto(codigo) {
      var resultado = this.arrEstadoProyecto.filter((x) => x.codigo == codigo);
      if (resultado != null && resultado.length > 0) {
        return resultado[0];
      }
      return "";
    },

    /** FIN VARIABLES APP */
  };
}
