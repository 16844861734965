import LayoutBody from '@/layout/layout-body'

export default [{
    path: '/nosotros',
    component: LayoutBody,
    children: [{
        path: '',
        component: () => import('@/views/nosotros/nosotros')
    },
    {
        path: '/nosotros/transparencia',
        component: () => import('@/views/nosotros/transparencia')
    },
    {
        path: '/nosotros/transparencia/documentos/:id',
        component: () => import('@/views/nosotros/documento-detalle')
    }
    ]
}]
