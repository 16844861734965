import LayoutBody from "@/layout/layout-body";

export default [
  {
    path: "/nuestrotrabajo",
    component: LayoutBody,
    children: [
      {
        path: "",
        component: () => import("@/views/nuestrotrabajo/nuestrotrabajo"),
      },
      {
        path: "/nuestrotrabajo/detalle/:id",
        component: () => import("@/views/nuestrotrabajo/nuestrotrabajo-detalle"),
      },
    ],
  },
];
