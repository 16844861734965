import LayoutBody from '@/layout/layout-body'

export default [{
    path: '/blog',
    component: LayoutBody,
    children: [{
        path: '',
        component: () => import('@/views/blog/blog')
    },
    {
        path: '/blog/detalle/:id',
        component: () => import('@/views/blog/blog-detalle')
    }]
}]
